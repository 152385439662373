@import "variables";
#sidenav{
    width:31%;
    left:0;
    z-index:-1;
}

#page{
    width:70%;
    float:left;
    margin-left: 30%;
    background:$white;
    #site-navigation{
        position:relative;
        top:auto;
        width:500px;
    }
}

.articlesGrid article{
    width:33.333333%;
}

#content{
    background-color:transparent;
    padding: 0 2em;
}
.fullpage-layout .entry-footer h4
	{
		font-size:24px;
	}