// cavanchor color codes
// =====================

$denim: #05445d;
$grey: #d4dcde;
$stone: #8ca1a0;
$aqua: #85c2bf;
$blue: #006699;
$green: #bdc373;
$darkness: #000;
$brown: #393232;
$white: #f6f6f6;
$offWhite: #efefef;
$greyBlue: $grey;
$blueGreen: #98a2a4;

$color__background-body: $white;
$color__background-screen: $white;
$color__background-hr: $brown;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: $denim;
$color__link-visited: $blue;
$color__link-hover: $denim;
$color__text-main: $brown;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;

//social pinterst & stumble uses google red

$twitter: #55acee;
$facebook: #3b5998;
$google: #dd4b39;

@font-face {
  font-family: "microbrew_tworegular";
  src: url("fonts/microbrew-two-webfont.eot");
  src: url("fonts/microbrew-two-webfont.eot?#iefix") format("embedded-opentype"),
    url("fonts/microbrew-two-webfont.woff") format("woff"),
    url("fonts/microbrew-two-webfont.ttf") format("truetype"),
    url("fonts/microbrew-two-webfont.svg#microbrew_tworegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "thirsty_rough_regregular";
  src: url("fonts/thirstyroughregular-webfont.eot");
  src: url("fonts/thirstyroughregular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/thirstyroughregular-webfont.woff") format("woff"),
    url("fonts/thirstyroughregular-webfont.ttf") format("truetype"),
    url("fonts/thirstyroughregular-webfont.svg#thirsty_rough_regregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

$font__main: Roboto, Arial, helvetica;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;

$font__huge: 4em;
$font__large: 2.2em;
$font__bigger: 1.875em; //30px
$font__big: 1.5em; //24px
$font__normal: 1em; //16px
$font__small: 0.75em; //14px

$thirsty: lobster, cursive;
$microbrew: microbrew_tworegular;
